exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-carousel-2-jsx": () => import("./../../../src/pages/components/Carousel-2.jsx" /* webpackChunkName: "component---src-pages-components-carousel-2-jsx" */),
  "component---src-pages-components-carousel-jsx": () => import("./../../../src/pages/components/Carousel.jsx" /* webpackChunkName: "component---src-pages-components-carousel-jsx" */),
  "component---src-pages-components-carousel-mobile-2-jsx": () => import("./../../../src/pages/components/CarouselMobile2.jsx" /* webpackChunkName: "component---src-pages-components-carousel-mobile-2-jsx" */),
  "component---src-pages-components-carousel-mobile-jsx": () => import("./../../../src/pages/components/CarouselMobile.jsx" /* webpackChunkName: "component---src-pages-components-carousel-mobile-jsx" */),
  "component---src-pages-components-contador-2-jsx": () => import("./../../../src/pages/components/Contador2.jsx" /* webpackChunkName: "component---src-pages-components-contador-2-jsx" */),
  "component---src-pages-components-contador-3-jsx": () => import("./../../../src/pages/components/Contador3.jsx" /* webpackChunkName: "component---src-pages-components-contador-3-jsx" */),
  "component---src-pages-components-contador-4-jsx": () => import("./../../../src/pages/components/Contador4.jsx" /* webpackChunkName: "component---src-pages-components-contador-4-jsx" */),
  "component---src-pages-components-contador-jsx": () => import("./../../../src/pages/components/Contador.jsx" /* webpackChunkName: "component---src-pages-components-contador-jsx" */),
  "component---src-pages-comunidades-index-jsx": () => import("./../../../src/pages/comunidades/index.jsx" /* webpackChunkName: "component---src-pages-comunidades-index-jsx" */),
  "component---src-pages-contacto-index-jsx": () => import("./../../../src/pages/contacto/index.jsx" /* webpackChunkName: "component---src-pages-contacto-index-jsx" */),
  "component---src-pages-elegir-card-jsx": () => import("./../../../src/pages/elegir/Card.jsx" /* webpackChunkName: "component---src-pages-elegir-card-jsx" */),
  "component---src-pages-elegir-card-mobile-jsx": () => import("./../../../src/pages/elegir/CardMobile.jsx" /* webpackChunkName: "component---src-pages-elegir-card-mobile-jsx" */),
  "component---src-pages-elegir-index-jsx": () => import("./../../../src/pages/elegir/index.jsx" /* webpackChunkName: "component---src-pages-elegir-index-jsx" */),
  "component---src-pages-eventos-index-jsx": () => import("./../../../src/pages/eventos/index.jsx" /* webpackChunkName: "component---src-pages-eventos-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marcas-index-jsx": () => import("./../../../src/pages/marcas/index.jsx" /* webpackChunkName: "component---src-pages-marcas-index-jsx" */),
  "component---src-pages-navbar-index-jsx": () => import("./../../../src/pages/navbar/index.jsx" /* webpackChunkName: "component---src-pages-navbar-index-jsx" */),
  "component---src-pages-puntos-index-jsx": () => import("./../../../src/pages/puntos/index.jsx" /* webpackChunkName: "component---src-pages-puntos-index-jsx" */)
}

